import { VscLoading } from "react-icons/vsc";

const LoadingSpinner = () => {
  return (
    <div className="fixed left-0 top-0 z-[999] flex h-screen w-screen items-center justify-center bg-gray-300 bg-opacity-25">
      <div className="rounded-md bg-white p-4">
        <div className="flex items-center justify-center gap-4">
          <VscLoading className="h-10 w-10 animate-spin text-plaved" />
          <span className="ml- text-lg">Cargando...</span>
        </div>
      </div>
    </div>
  );
};

export default LoadingSpinner;
