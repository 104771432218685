import { createContext, useContext, useEffect, useRef, useState } from "react";

const isBrowser = typeof window !== "undefined";
import { useRouter } from "next/router";
import { trackEvent } from "../Utils/trackEvent";

interface IGobalState {
  isAddReportOpen: boolean;
  setIsAddReportOpen: (value: boolean) => void;
  setIsSessionInspection: (value: boolean) => void;
  isSessionInspection: boolean;
  setIsSidebarOpen: (value: boolean) => void;
  isSidebarOpen: boolean;
  setIsAddNotesOpen: (value: boolean) => void;
  isAddNotesOpen: boolean;
  setSessionData: (value: any) => void;
  sessionData: any;
  sessionObject: any;
  setSessionObject: (value: any) => void;
  customerLogo: any;
  setCustomerLogo: (value: any) => void;
  isNotificationOpen: boolean;
  setIsNotificationOpen: (value: boolean) => void;
  setIsSearch: (value: boolean) => void;
  isSearch: boolean;
  reportView: any;
  setReportView: (value: any) => void;
  isOverlayActive: boolean;
  setOverlayActive: (value: any) => void;
  seletedVisitType: number;
  setSelectedVisitType: (value: number) => void;
  isPrjectPanelOpen: boolean;
  setIsProjectPanelOpen: (value: boolean) => void;
  setOpenIssueModal: (value: boolean) => void;
  openIssueModal: boolean;
  isDeleting: boolean;
  setIsDeleting: (value: boolean) => void;
  associatedInspections: any;
  setAssociatedInspections: (value: any) => void;
  setIssidebarVisible: (value: boolean) => void;
  isSidebarVisible: boolean;
  isPaywallOpen: boolean;
  setIsPaywallOpen: (value: boolean) => void;
}

export const AppContext = createContext({} as IGobalState);

export function AppWrapper({ children }: any) {
  const router = useRouter();
  const [isAddReportOpen, setIsAddReportOpen] = useState<boolean>(false);
  const [isPrjectPanelOpen, setIsProjectPanelOpen] = useState<boolean>(false);
  const [isSessionInspection, setIsSessionInspection] =
    useState<boolean>(false);
  const [isPaywallOpen, setIsPaywallOpen] = useState<boolean>(false);
  const [isAddNotesOpen, setIsAddNotesOpen] = useState<boolean>(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
  const [isNotificationOpen, setIsNotificationOpen] = useState<boolean>(false);
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [seletedVisitType, setSelectedVisitType] = useState(1);
  const [openIssueModal, setOpenIssueModal] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [associatedInspections, setAssociatedInspections] = useState([]);
  const [isSidebarVisible, setIssidebarVisible] = useState<boolean>(false);

  const initialSessionData = () => {
    if (!isBrowser) return {};
    const localData = localStorage.getItem("sessionData");
    return localData ? JSON.parse(localData) : {};
  };

  const initialSessionObject = () => {
    if (!isBrowser) return {};
    const localData = localStorage.getItem("sessionObject");
    return localData !== null && localData !== "undefined"
      ? JSON.parse(localData)
      : {};
  };

  const [sessionData, setSessionData] = useState<any>(initialSessionData());
  const [sessionObject, setSessionObject] = useState<any>(
    initialSessionObject()
  );
  const [customerLogo, setCustomerLogo] = useState<any>(null);
  const [reportView, setReportView] = useState<any>({});
  const [isOverlayActive, setOverlayActive] = useState<boolean>(false);

  const usePrevious = <T extends unknown>(value: T): T | undefined => {
    const ref = useRef<T>();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevState = usePrevious({ sessionData, sessionObject });

  useEffect(() => {
    if (!isBrowser) return;

    if (sessionData !== prevState?.sessionData) {
      localStorage.setItem("sessionData", JSON.stringify(sessionData));
    }

    if (sessionObject !== prevState?.sessionObject) {
      localStorage.setItem("sessionObject", JSON.stringify(sessionObject));
    }
  }, [sessionData, sessionObject]);

  // Track changes to isPaywallOpen and send Amplitude event
  useEffect(() => {
    if (isPaywallOpen) {
     trackEvent("Trigger Paywall Modal", {
        page: router.pathname
      })
    }
  }, [isPaywallOpen]);

  return (
    <AppContext.Provider
      value={{
        setIsProjectPanelOpen,
        isPrjectPanelOpen,
        setIsSessionInspection,
        isSessionInspection,
        setIsSidebarOpen,
        isSidebarOpen,
        setIsAddNotesOpen,
        isAddNotesOpen,
        isAddReportOpen,
        setIsAddReportOpen,
        setSessionData,
        sessionData,
        sessionObject,
        setSessionObject,
        customerLogo,
        setCustomerLogo,
        setIsNotificationOpen,
        isNotificationOpen,
        setReportView,
        reportView,
        isOverlayActive,
        setOverlayActive,
        setIsSearch,
        isSearch,
        seletedVisitType,
        setSelectedVisitType,
        openIssueModal,
        setOpenIssueModal,
        isDeleting,
        setIsDeleting,
        associatedInspections,
        setAssociatedInspections,
        isSidebarVisible,
        setIssidebarVisible,
        isPaywallOpen,
        setIsPaywallOpen,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}