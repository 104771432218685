import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "react-phone-number-input/style.css";
import "react-toastify/dist/ReactToastify.css";
import "../styles/globals.css";

import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { UserProvider, useUser } from "@auth0/nextjs-auth0/client";
import { SpeedInsights } from "@vercel/speed-insights/next";
import { ToastContainer } from "react-toastify";

import LoadingSpinner from "../Components/LoadingSpinner";
import { AppWrapper } from "../context/state";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPerformance from "@bugsnag/browser-performance";
import Head from "next/head";
import * as amplitude from '@amplitude/analytics-browser';
import * as sessionReplay from '@amplitude/session-replay-browser';

const AMPLITUDE_API_KEY = "dee622db9fc22974a61a156d496d9851";
import React from "react";

// Inicialización de Bugsnag
if (!Bugsnag._client) {
  Bugsnag.start({
    apiKey: "1a657fa52ee13ce9f07960a1ed527d51",
    plugins: [new BugsnagPluginReact()],
  });
  BugsnagPerformance.start({ apiKey: "1a657fa52ee13ce9f07960a1ed527d51" });
}


const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);


function UserInitializer() {
  const { user, isLoading } = useUser();
  const identified = useRef(false);

  useEffect(() => {
    if (!isLoading && user && !identified.current) {
      amplitude.setUserId(user.sub);
      const identifyEvent = new amplitude.Identify()
        .set("email", user.email)
        .set("name", user.name)
        .set("role", user.user_roles?.[0] || "none")
        .set("company", user.user_metadata?.companyName || "none");

      amplitude.identify(identifyEvent);
      identified.current = true; // Prevent re-identification

      console.log("User Identified successfully.")
    }
  }, [user, isLoading]);

  return null; // This component doesn't need to render anything
}

function Plaved({ Component, pageProps }) {
  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();

  // Manejo de la navegación y el estado de carga
  useEffect(() => {
    const handleRouteChangeStart = () => {
      setIsLoading(true);
    };
    const handleRouteChangeComplete = () => {
      setIsLoading(false);
    };

    router.events.on("routeChangeStart", handleRouteChangeStart);
    router.events.on("routeChangeComplete", handleRouteChangeComplete);
    router.events.on("routeChangeError", handleRouteChangeComplete);

    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
      router.events.off("routeChangeError", handleRouteChangeComplete);
    };
  }, [router.events]);


  useEffect(() => {
    const initializeAmplitude = async () => {
      if (!amplitude.initialized) {
        // Inicialización de Amplitude
        amplitude.init(AMPLITUDE_API_KEY, {
          autocapture: true,
          serverZone: "EU",
        });
        amplitude.initialized = true; // Evita reinicializaciones
  
        const currentSessionId = amplitude.getSessionId();
        // Inicialización de Session Replay
        await sessionReplay.init(AMPLITUDE_API_KEY, {
          sampleRate: 1, // Ajusta la tasa de muestreo según lo necesites
          optOut: false, // Establece en true si el usuario decide no participar
          sessionId: currentSessionId,
          serverZone: "EU",
        }).promise;
  
        console.log("Session Replay & Amplitude initialized");
      }
    };
  
    initializeAmplitude();
  }, []);

  // Registro del Service Worker
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", () => {
        navigator.serviceWorker
          .register("/service-worker.js", { scope: "/" })
          .then((registration) => {
            console.log(
              "Service Worker registrado con scope:",
              registration.scope,
            );
          })
          .catch((error) => {
            console.error("Error al registrar el Service Worker:", error);
          });
      });
    }
  }, []);

  return (
    <ErrorBoundary>
      <UserProvider>
      <UserInitializer />

        <AppWrapper>
          <Head>
            <title>PLAVED</title>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
            />
          </Head>

          {isLoading && <LoadingSpinner />}

          <ToastContainer
            position="top-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />

          <Component {...pageProps} />

      
          <SpeedInsights />
        </AppWrapper>
      </UserProvider>
    </ErrorBoundary>
  );
}

export default Plaved;